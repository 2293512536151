import { useAppData } from "src/context/useAppData"
import { clearAuthStorage } from "src/data/auth/authStorage"
import { useOrganizationQueryId } from "src/data/organizations/hooks/useOrganizationQueryId"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useFlags } from "src/hooks/useFlags"
import { useUrlParam } from "src/hooks/useUrlParam"
import { Routes } from "src/router/routes"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { debug, logger } from "src/utils/logger"

export function Logout() {
  const { oauthEnabled, logoutDebugger } = useFlags()
  const { clear: clearAppData } = useAppData()
  const { reset: resetFlags } = useFlags()
  const { replaceOrgIdParam } = useOrganizationQueryId()
  const { value: logoutReason } = useUrlParam("reason")

  function clearLoginData() {
    debug.log("clear login data")
    clearAppData()
    clearAuthStorage()
    resetFlags()
    replaceOrgIdParam(undefined)
  }

  useEffectOnce(() => {
    logger.warn("Logging out", { reason: logoutReason })
    if (logoutDebugger) {
      // eslint-disable-next-line no-debugger
      debugger
    }
    clearLoginData()
    if (oauthEnabled) {
      window.location.href = Routes.BELogout.location().pathname
    } else {
      window.location.href = Routes.Login.location().pathname
    }
  })
  return <LoadingScreen debugInfo={"Logging out"} />
}
